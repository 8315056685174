import axios from 'axios';

const url =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:9090/'
    : 'https://id.noori2020.com/';

    console.log(process.env.NODE_ENV);

const apiClient = axios.create({
  baseURL: url,
});

apiClient.interceptors.request.use((request) => {
  const accessToken = localStorage.getItem('token');
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
    request.headers.AccessToken = accessToken;
  }
  return request;
});

// apiClient.interceptors.response.use(undefined, (error) => {
//   // Errors handling
//   const { response } = error;
//   const { data } = response;
//   console.log(data);
//   if (data) {
//     // notification.warning({
//     //   message: data.message,
//     // })
//     console.log(data);
//   }
// });

export default apiClient;
