import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import apiClient from '../api/axios';
import useAuth from '../Hooks/useAuth';

export class FindUrlDto {
  email = '';
}

function FindUrlPage() {
  const { auth } = useAuth();
  const [findUrlDto, setFindUrlDto] = useState(new FindUrlDto());
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState('');
  const [subsUrl, setSubsUrl] = useState('');

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'trafficamount' || name === 'price') {
      setFindUrlDto({
        ...findUrlDto,
        [name]: +value,
        helperEmail: auth.email,
      });
    } else {
      setFindUrlDto({
        ...findUrlDto,
        [name]: value,
        helperEmail: auth.email,
      });
    }
  };

  const handlesumit = async (e) => {
    e.preventDefault();
    setErrMsg('');
    setSuccess('처리중...');
    try {
      const response = await apiClient.post(
        'find_subscription',
        JSON.stringify(findUrlDto),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const result = response?.data;
      setSuccess(`찾기성공 >>>${result.email}`);
      setSubsUrl(result.shorturl);
    } catch (error) {
      if (!error?.response) {
        setErrMsg('응답 없음');
      } else if (error.response?.status === 400) {
        setErrMsg(`입력 오류 ${error.response.data.message}`);
      } else {
        setErrMsg('서버 문제 발생');
      }
      console.log(error);
      setSuccess('');
    }
  };

  return (
    <div className='min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8'>
      <div className='max-w-md w-full space-y-8'>
        <div>
          <h2 className='text-center text-3xl font-extrabold text-gray-200'>
            구독주소찾기
          </h2>
        </div>
        <form onSubmit={handlesumit} className='mt-8 space-y-4'>
          <div>
            <input
              id='email'
              name='email'
              onChange={handleChange}
              required
              className='focus:border-blue-500 w-full p-4 text-sm bg-gray-800 focus:outline-none  border border-gray-500 rounded text-gray-100'
              type='text'
              placeholder='Email'
              value={findUrlDto.email}
            />
          </div>

          <div>
            <button
              disabled={Object.values(findUrlDto).some((e) => e === '')}
              className='disabled:bg-gray-400 w-full py-4 bg-blue-600 hover:bg-blue-700 rounded text-sm font-bold text-gray-50 transition duration-200'
            >
              구독주소찾기
            </button>
          </div>
        </form>
        <div className='text-red-500 row-auto text-sm'>
          <div>{errMsg}</div>
        </div>
        <div className='text-green-500 row-auto text-sm'>
          <div>{success}</div>
        </div>
        <div className='flex flex-row'>
          <div className='focus:border-blue-500 basis-4/5 p-4 text-sm bg-gray-800 focus:outline-none border border-gray-200 rounded text-gray-50'>
            {subsUrl ? `https://${subsUrl}` : '구독주소를 먼저 찾으세요...'}
          </div>
          <CopyToClipboard
            text={subsUrl ? `https://${subsUrl}` : ''}
            onCopy={() => alert(`https://${subsUrl} 복사되었습니다.`)}
          >
            <button
              type='dashed'
              className='focus:border-blue-500 basis-1/5 p-4 text-sm col-span-1 bg-gray-500 focus:outline-none border border-gray-200 rounded text-gray-50'
              disabled={!subsUrl}
            >
            복사
            </button>
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
}

export default FindUrlPage;
