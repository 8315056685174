import { useState } from 'react';
import apiClient from '../api/axios';
import useAuth from '../Hooks/useAuth';

export class makeExtendDto {
  email = '';
  price = 480;
  helperEmail = '';
  extendDuration = 12;
  productType = 'gold';
  payway = 'wechat';
}

function ExtendDuraion() {
  const { auth } = useAuth();
  const [extendDto, setExtendDto] = useState(new makeExtendDto());
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'price' || name === 'extendDuration') {
      setExtendDto({ ...extendDto, [name]: +value, helperEmail: auth.email });
    } else {
      setExtendDto({ ...extendDto, [name]: value, helperEmail: auth.email });
    }
  };

  const handlesumit = async (e) => {
    e.preventDefault();
    setErrMsg('');
    setSuccess('처리중...');
    try {
      const response = await apiClient.post(
        'extend_duration',
        JSON.stringify(extendDto),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const result = response?.data;
      setSuccess(`연장성공! >>>${result.email}`);
    } catch (error) {
      if (!error?.response) {
        setErrMsg('응답 없음');
      } else if (error.response?.status === 400) {
        setErrMsg(`입력 오류 ${error.response.data.message}`);
      } else {
        setErrMsg('서버 문제 발생');
      }
      console.log(error);
      setSuccess('');
    }
  };

  return (
    <div className='min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8'>
      <div className='max-w-md w-full space-y-8'>
        <div>
          <h2 className='text-center text-3xl font-extrabold text-gray-200'>
            기간연장
          </h2>
        </div>
        <form onSubmit={handlesumit} className='mt-8 space-y-4'>
          <div>
            <input
              id='email'
              name='email'
              onChange={handleChange}
              required
              className='focus:border-blue-500 w-full p-4 text-sm bg-gray-800 focus:outline-none  border border-gray-500 rounded text-gray-100'
              type='text'
              placeholder='Email'
              value={extendDto.email}
            />
          </div>

          <select
            className='focus:border-blue-500 w-full p-4 text-sm bg-gray-800 focus:outline-none  border border-gray-500 rounded text-gray-100'
            name='productType'
            required
            onChange={handleChange}
            value={extendDto.productType}
          >
            <option value='gold'>고급형</option>
            <option value='diamond'>VIP형</option>
            <option value='silver'>실속형</option>
            <option value='tv'>TV</option>
            <option value='router'>공유기</option>
            <option value='free'>체험판</option>
          </select>
          <select
            className='focus:border-blue-500 w-full p-4 text-sm bg-gray-800 focus:outline-none  border border-gray-500 rounded text-gray-100'
            name='extendDuration'
            required
            onChange={handleChange}
            value={extendDto.extendDuration}
          >
            <option value={12}>12개월</option>
            <option value={1}>1개월</option>
            <option value={3}>3개월</option>
            <option value={6}>6개월</option>
          </select>
          <select
            className='focus:border-blue-500 w-full p-4 text-sm bg-gray-800 focus:outline-none  border border-gray-500 rounded text-gray-100'
            name='payway'
            onChange={handleChange}
            value={extendDto.payway}
          >
            <option value='wechat'>위쳇페이</option>
            <option value='alipay'>알리페이</option>
            <option value='other'>기타</option>
          </select>
          <div>
            <input
              id='price'
              name='price'
              onChange={handleChange}
              required
              className='focus:border-blue-500 w-full p-4 text-sm bg-gray-800 focus:outline-none  border border-gray-500 rounded text-gray-100'
              type='text'
              placeholder='가격'
              value={extendDto.price}
            />
          </div>
          <div>
            <button
              disabled={Object.values(extendDto).some((e) => e === '')}
              className='disabled:bg-gray-400 w-full py-4 bg-blue-600 hover:bg-blue-700 rounded text-sm font-bold text-gray-50 transition duration-200'
            >
              연장하기
            </button>
          </div>
        </form>
        <div className='text-red-500 row-auto text-sm'>
          <div>{errMsg}</div>
        </div>
        <div className='text-green-500 row-auto text-sm'>
          <div>{success}</div>
        </div>
      </div>
    </div>
  );
}

export default ExtendDuraion;
