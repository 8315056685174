import React, { useRef, useEffect, useState } from 'react';
import apiClient from './api/axios';
import { useNavigate } from 'react-router-dom';

const REX_PASS =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=^[a-zA-Z0-9]*$).{8,40}$/;
const REX_EMAIL = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

function Register() {
  const emailRef = useRef();

  const [email, setEmail] = useState('');
  const [pwd, setpwd] = useState('');
  const [matchPwd, setMatchPwd] = useState('');

  const [vaildEmail, setvaildEmail] = useState(false);
  const [validPass, setValidPass] = useState(false);
  const [validMatchPwd, setValidMatchPwd] = useState(false);

  const [errMsg, setErrMsg] = useState('');


  const navigate = useNavigate();

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  useEffect(() => {
    const result = REX_PASS.test(pwd);
    const emailresult = REX_EMAIL.test(email);
    console.log(result);
    setValidPass(result);
    setvaildEmail(emailresult);
    if (pwd) {
      const match = pwd === matchPwd;
      setValidMatchPwd(match);
    }
  }, [email, pwd, matchPwd]);

  const handlesumit = async (e) => {
    e.preventDefault();
    try {
      setErrMsg('처리중...')
      const response = await apiClient.post(
        'test/make_User',
        JSON.stringify({ email, password: pwd }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      console.log(response.data);
      console.log(response.token);
      console.log(JSON.stringify(response));
      setErrMsg('')
      navigate('/', { replace: true });
    } catch (error) {
      if (!error?.response) {
        console.log('somthing wrong!');
      } else if (error.response?.status === 409) {
        console.log('somthing wrong! 409');
      } else {
        console.log('sonething not working');
      }
    }
  };

  return (
    <section className='flex justify-center items-center h-screen bg-gray-800'>
      <div className='max-w-md w-full bg-white rounded p-6 space-y-4'>
        <div className='mb-4'>
          <p className='text-gray-600'>Sign Up</p>
          <h2 className='text-xl font-bold'>NOORI SYSTEM</h2>
        </div>
        <form onSubmit={handlesumit}>
          <div className='mb-4'>
            <input
              id='email'
              autoComplete='on'
              onChange={(e) => setEmail(e.target.value)}
              required
              ref={emailRef}
              className={
                vaildEmail
                  ? 'w-full p-4 text-sm bg-gray-50 focus:outline-none border focus:border-blue-500 rounded text-gray-600'
                  : 'w-full p-4 text-sm bg-gray-50 focus:outline-none border focus:border-red-500 rounded text-gray-600'
              }
              type='text'
              placeholder='Email'
            />
          </div>
          <div className='mb-4'>
            <input
              id='pwd'
              onChange={(e) => setpwd(e.target.value)}
              required
              className={
                validPass
                  ? 'w-full p-4 text-sm bg-gray-50 focus:outline-none border focus:border-blue-500 rounded text-gray-600'
                  : 'w-full p-4 text-sm bg-gray-50 focus:outline-none border focus:border-red-500 rounded text-gray-600'
              }
              type='password'
              placeholder='Password'
            />
          </div>
          <div className='mb-4'>
            <input
              id='pwdmatch'
              onChange={(e) => setMatchPwd(e.target.value)}
              required
              className={
                validMatchPwd
                  ? 'w-full p-4 text-sm bg-gray-50 focus:outline-none border focus:border-blue-500 rounded text-gray-600'
                  : 'w-full p-4 text-sm bg-gray-50 focus:outline-none border focus:border-red-500 rounded text-gray-600'
              }
              type='text'
              placeholder='Password confirm'
            />
          </div>
          <div>
            <button
              disabled={validPass && validMatchPwd && vaildEmail && !errMsg ? false : true}
              className=' disabled:bg-gray-400 w-full py-4 bg-blue-600 hover:bg-blue-700 rounded text-sm font-bold text-gray-50 transition duration-200'
            >
              가입하기
            </button>
          </div>
        </form>
        <div className='text-red-500 row-auto text-sm'>
        <div>{errMsg}</div>
          <div>{vaildEmail ? '' : '실제 이메일 입력'}</div>
          <div>
            {validPass ? '' : '특수문자 없이 대문자+소문자+숫자 8자 이상'}
          </div>
          <div>{validMatchPwd ? '' : '비밀번호 불일치'}</div>
        </div>
      </div>
    </section>
  );
}

export default Register;
