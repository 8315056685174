/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useAuth from '../Hooks/useAuth';
import apiClient from '../api/axios';

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { auth, setAuth } = useAuth();
  
  useEffect(() => {
    let isMounted = true;

    const persistGet = async () => {
      try {
        console.log('first')
        const response = await apiClient.get('persist');
        const token = response?.data?.token;
        const role = response?.data?.role;
        const email = response?.data?.email;
        localStorage.setItem('token', token);
        console.log('persist send', token)
        setAuth({ token, role, email });
      } catch (error) {
        console.log(error);
      } finally {
        isMounted && setIsLoading(false);
      }
    };
    if (!auth.Token && isMounted)  {
      persistGet();
    } else {
      setIsLoading(false);
    }

    return () => (isMounted = false);
  }, []);

  return <>{isLoading ? <p>Loading...</p> : <Outlet />}</>;
};

export default PersistLogin;
