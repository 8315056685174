import SignIn from './Signin';
import Layout from './layout/Layout';
import Register from './Register';
import Home from './home/Home';
import Missing from './home/Missing';
import UserList from './home/UserList';
import AddUser from './home/AddUser';
import Addtraffic from './home/AddTraffic';
import RequireAuth from './layout/RequireAuth';
import { Routes, Route } from 'react-router-dom';
import ExtendDuraion from './home/Extend';
import FindUrlPage from './home/FindUrl';
import PersistLogin from './components/persistLogin';
import AddRecoPage from './home/AddReco';

function App() {
  return (
    <Routes>
      <Route path='/signin' element={<SignIn />} />
      <Route path='/register' element={<Register />} />

      <Route element={<PersistLogin />}>
        <Route element={<RequireAuth allowedRoles={[2222]} />}>
          <Route path='/' element={<Layout />}>
            <Route path='/' element={<Home />} />
            <Route path='/userlist' element={<UserList />} />
            <Route path='/extend' element={<ExtendDuraion />} />
            <Route path='/adduser' element={<AddUser />} />
            <Route path='/traffic' element={<Addtraffic />} />
            <Route path='/findurl' element={<FindUrlPage />} />
            <Route path='/reco' element={<AddRecoPage />} />
          </Route>
        </Route>
      </Route>

      <Route path='/missing' element={<Missing />} />
      <Route path='*' element={<Missing />} />
    </Routes>
  );
}

export default App;
