import { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import apiClient from '../api/axios';
import useAuth from '../Hooks/useAuth';
import { LockClosedIcon } from '@heroicons/react/solid';

export class makeUserDto {
  email = '';
  productType = 'gold';
  duration = 12;
  price = 480;
  helperEmail = '';
  phone = '';
  name = '';
  payway = 'wechat';
}

const REX_EMAIL = /^[a-z0-9.!#$%&*+/=?^_`{|}~-]+@[a-z0-9-]+(?:\.[a-z0-9-]+)*$/;

function AddUser() {
  const { auth } = useAuth();
  const [userDto, setUserDto] = useState(new makeUserDto());
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState('');
  const [subsUrl, setSubsUrl] = useState('');

  useEffect(() => {
    const emailresult = REX_EMAIL.test(userDto.email);
    console.log(emailresult);

    if (!emailresult) {
      setErrMsg('email 형식이 잘못됨...');
    } else {
      setErrMsg('');
    }
  }, [userDto]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'duration' || name === 'price') {
      setUserDto({ ...userDto, [name]: +value, helperEmail: auth.email });
    } else {
      setUserDto({ ...userDto, [name]: value, helperEmail: auth.email });
    }
  };

  const handlesumit = async (e) => {
    e.preventDefault();
    setErrMsg('');
    setSuccess('처리중...');
    try {
      const response = await apiClient.post(
        'make_user',
        JSON.stringify(userDto),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const result = response?.data;
      setSuccess(`계정추가 성공! >>>${result.email}`);
      setSubsUrl(result.shorturl);
    } catch (error) {
      if (!error?.response) {
        setErrMsg('응답 없음');
      } else if (error.response?.status === 400) {
        setErrMsg(`입력 오류 ${error.response.data.message}`);
      } else {
        setErrMsg('서버 문제 발생');
      }
      console.log(error);
      setSuccess('');
    }
  };

  return (
    <div className='min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8'>
      <div className='max-w-md w-full space-y-8'>
        <div>
          <h2 className='text-center text-3xl font-extrabold text-gray-200'>
            계정생성
          </h2>
        </div>
        <form onSubmit={handlesumit} className='mt-8 space-y-6'>
          <div className='rounded-md shadow-sm -space-y-px'>
            <div>
              <input
                id='email'
                name='email'
                onChange={handleChange}
                required
                className='focus:border-blue-500 w-full p-4 text-sm bg-gray-800 focus:outline-none  border border-gray-500 rounded text-gray-100'
                type='text'
                placeholder='Email'
                value={userDto.email}
              />
            </div>
          </div>

          <div>
            <input
              id='name'
              name='name'
              onChange={handleChange}
              required
              className='focus:border-blue-500 w-full p-4 text-sm bg-gray-800 focus:outline-none  border border-gray-500 rounded text-gray-100'
              type='text'
              placeholder='이름'
              value={userDto.name}
            />
          </div>
          <div>
            <input
              id='phone'
              name='phone'
              onChange={handleChange}
              required
              className='focus:border-blue-500 w-full p-4 text-sm bg-gray-800 focus:outline-none  border border-gray-500 rounded text-gray-100'
              type='text'
              placeholder='전화번호'
              value={userDto.phone}
            />
          </div>

          <select
            className='focus:border-blue-500 w-full p-4 text-sm bg-gray-800 focus:outline-none  border border-gray-500 rounded text-gray-100'
            name='productType'
            required
            onChange={handleChange}
            value={userDto.productType}
          >
            <option value='free'>체험판</option>
            <option value='silver'>실속형</option>
            <option value='gold'>고급형</option>
            <option value='diamond'>VIP형</option>
            <option value='tv'>TV</option>
            <option value='router'>공유기</option>
          </select>
          <select
            className='focus:border-blue-500 w-full p-4 text-sm bg-gray-800 focus:outline-none  border border-gray-500 rounded text-gray-100'
            name='duration'
            required
            onChange={handleChange}
            value={userDto.duration}
          >
            <option value={1}>1개월</option>
            <option value={3}>3개월</option>
            <option value={6}>6개월</option>
            <option value={12}>12개월</option>
          </select>
          <select
            className='focus:border-blue-500 w-full p-4 text-sm bg-gray-800 focus:outline-none  border border-gray-500 rounded text-gray-100'
            name='payway'
            onChange={handleChange}
            value={userDto.payway}
          >
            <option value='wechat'>위쳇페이</option>
            <option value='alipay'>알리페이</option>
            <option value='other'>기타</option>
          </select>
          <div>
            <input
              id='price'
              name='price'
              onChange={handleChange}
              required
              className='focus:border-blue-500 w-full p-4 text-sm bg-gray-800 focus:outline-none  border border-gray-500 rounded text-gray-100'
              type='text'
              placeholder='가격'
              value={userDto.price}
            />
          </div>
          <div>
            <button
              disabled={Object.values(userDto).some((e) => e === '') || errMsg}
              className='disabled:bg-gray-400 w-full py-4 bg-blue-600 hover:bg-blue-700 rounded text-sm font-bold text-gray-50 transition duration-200'
            >
              계정만들기
            </button>
          </div>
        </form>
        <div className='text-red-500 row-auto text-sm'>
          <div>{errMsg}</div>
        </div>
        <div className='text-green-500 row-auto text-sm'>
          <div>{success}</div>
        </div>
        <div>
          <div className='focus:border-blue-500 mb-5 w-full p-4 text-sm bg-gray-800 focus:outline-none  border border-gray-500 rounded text-gray-100'>
            {subsUrl ? `https://${subsUrl}` : '구독주소가 생성되면 나옵니다.'}
          </div>
          <CopyToClipboard
            text={subsUrl ? `https://${subsUrl}` : ''}
            onCopy={() => alert('success')}
          >
            <button
              type='submit'
              className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            >
              <span className='absolute left-0 inset-y-0 flex items-center pl-3'>
                <LockClosedIcon
                  className='h-5 w-5 text-indigo-500 group-hover:text-indigo-400'
                  aria-hidden='true'
                />
              </span>
              복사하기
            </button>
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
}

export default AddUser;
