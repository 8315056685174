import { useState } from 'react';
import apiClient from '../api/axios';
import useAuth from '../Hooks/useAuth';

export class makeExtendDto {
  email = '';
  trafficamount = 50;
  payway = 'wechat';
  price = 50;
  helperEmail = '';
}

function AddTrafficAction(traffic) {
  const { auth } = useAuth();
  const [addTrafficDto, serAddtrafficDto] = useState(new makeExtendDto());
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'trafficamount' || name === 'price') {
      serAddtrafficDto({
        ...addTrafficDto,
        [name]: +value,
        helperEmail: auth.email,
      });
    } else {
      serAddtrafficDto({
        ...addTrafficDto,
        [name]: value,
        helperEmail: auth.email,
      });
    }
  };

  const handlesumit = async (e) => {
    e.preventDefault();
    setErrMsg('');
    setSuccess('처리중...');
    try {
      const response = await apiClient.post(
        'add_traffic',
        JSON.stringify(addTrafficDto),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const result = response?.data;
      setSuccess(`용량추가 성공! >>>${result.email}`);
    } catch (error) {
      if (!error?.response) {
        setErrMsg('응답 없음');
      } else if (error.response?.status === 400) {
        setErrMsg(`입력 오류 ${error.response.data.message}`);
      } else {
        setErrMsg('서버 문제 발생');
      }
      console.log(error);
      setSuccess('');
    }
  };

  return (
    <>
      <div className='min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8'>
        <div className='max-w-md w-full space-y-8'>
          <div>
            <h2 className='text-center text-3xl font-extrabold text-gray-200'>
              용량추가하기
            </h2>
          </div>
          <form onSubmit={handlesumit} className=' mt-8 space-y-4'>
            <div className='rounded-md shadow-sm space-y-5'>
              <div>
                <input
                  id='email'
                  name='email'
                  onChange={handleChange}
                  required
                  className='focus:border-blue-500 w-full p-4 text-sm bg-gray-800 focus:outline-none  border border-gray-500 rounded text-gray-100'
                  type='text'
                  placeholder='Email'
                  value={addTrafficDto.email}
                />
              </div>


              <select
                className='focus:border-blue-500 w-full p-4 text-sm bg-gray-800 focus:outline-none  border border-gray-500 rounded text-gray-100'
                name='trafficamount'
                required
                onChange={handleChange}
                value={addTrafficDto.trafficamount}
              >
                <option value={50}>50기가</option>
                <option value={25}>25기가</option>
                <option value={100}>100기가</option>
                <option value={0}>풀기만</option>
              </select>

              <select
                className='focus:border-blue-500 w-full p-4 text-sm bg-gray-800 focus:outline-none  border border-gray-500 rounded text-gray-100'
                name='payway'
                onChange={handleChange}
                value={addTrafficDto.payway}
              >
                <option value='wechat'>위쳇페이</option>
                <option value='alipay'>알리페이</option>
                <option value='other'>기타</option>
              </select>
              <div>
                <input
                  id='price'
                  name='price'
                  onChange={handleChange}
                  required
                  className='focus:border-blue-500 w-full p-4 text-sm bg-gray-800 focus:outline-none  border border-gray-500 rounded text-gray-100'
                  type='text'
                  placeholder='가격'
                  value={addTrafficDto.price}
                />
              </div>
              
              <div>
                <button
                  disabled={Object.values(addTrafficDto).some((e) => e === '')}
                  className='disabled:bg-gray-400 w-full py-4 bg-blue-600 hover:bg-blue-700 rounded text-sm font-bold text-gray-50 transition duration-200'
                >
                  용량추가하기
                </button>
              </div>
            </div>
          </form>
          <div className='text-red-500 row-auto text-sm'>
            <div>{errMsg}</div>
          </div>
          <div className='text-green-500 row-auto text-sm'>
            <div>{success}</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddTrafficAction;
