import useAuth from '../Hooks/useAuth';

export default function Home() {
  const { auth } = useAuth();

  return (
    <>
      <div className='min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8'>
        <div className='max-w-md w-full space-y-8'>
          <div className='text-center text-xl font-extrabold text-gray-200 space-y-5'>
            <p>관리자 홈 (v0.1.2)</p>
            <p>{auth.email} </p>
            <p>MAIN 데이터베이스 로그인</p>
            <p>주의사항</p>
            <p>옵션 변경시 기본 가격 수정필요</p>
          </div>
        </div>
      </div>
    </>
  );
}
