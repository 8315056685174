function Missing() {
  return (
    <div className='min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8'>
      <div className='max-w-md w-full space-y-8'>
        <div>
          <h2 className='text-center text-3xl font-extrabold text-gray-200'>
            인증 실패
          </h2>
        </div>
        
      </div>
    </div>
  );
}

export default Missing;
