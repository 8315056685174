import { useState } from 'react';

import apiClient from '../api/axios';
import useAuth from '../Hooks/useAuth';

export class AddRecoDto {
  newEmail = '';
  recoEmail = '';
}

function AddRecoPage() {
  const { auth } = useAuth();
  const [addRecoDto, setAddrecoDto] = useState(new AddRecoDto());
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAddrecoDto({
      ...addRecoDto,
      [name]: value,
      helperEmail: auth.email,
    });
  };

  const handlesumit = async (e) => {
    e.preventDefault();
    setErrMsg('');
    setSuccess('처리중...');
    try {
      const response = await apiClient.post(
        'add_reco',
        JSON.stringify(addRecoDto),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const result = response?.data;
      setSuccess(`${result.email} >>> 1달 연장됨`);
    } catch (error) {
      if (!error?.response) {
        setErrMsg('응답 없음');
      } else if (error.response?.status === 400) {
        setErrMsg(`입력 오류 ${error.response.data.message}`);
      } else {
        setErrMsg('서버 문제 발생');
      }
      console.log(error);
      setSuccess('');
    }
  };

  return (
    <div className='min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8'>
      <div className='max-w-md w-full space-y-8'>
        <div>
          <h2 className='text-center text-3xl font-extrabold text-gray-200'>
            추천인 추가
          </h2>
        </div>
        <form onSubmit={handlesumit} className='mt-8 space-y-4'>
          <div className='flex flex-row text-center'>
            <div className='focus:border-blue-500 basis-1/4 p-4 text-sm col-span-1 bg-gray-700 focus:outline-none border border-gray-200 rounded text-gray-50'>
              신규가입인
            </div>

            <input
              id='newEmail'
              name='newEmail'
              onChange={handleChange}
              required
              className='focus:border-blue-500 basis-3/4 p-4 text-sm bg-gray-800 focus:outline-none  border border-gray-500 rounded text-gray-100'
              type='text'
              placeholder='Email'
              value={addRecoDto.email}
            />
          </div>
          <div className='flex flex-row text-center'>
            <div className='focus:border-blue-500 basis-1/4 p-4 text-sm col-span-1 bg-gray-700 focus:outline-none border border-gray-200 rounded text-gray-50'>
              기존추천인
            </div>
            <input
              id='recoEmail'
              name='recoEmail'
              onChange={handleChange}
              required
              className='focus:border-blue-500 basis-3/4 p-4 text-sm bg-gray-800 focus:outline-none  border border-gray-500 rounded text-gray-100'
              type='text'
              placeholder='Email'
              value={addRecoDto.email}
            />
          </div>

          <div>
            <button
              disabled={Object.values(addRecoDto).some((e) => e === '')}
              className='disabled:bg-gray-400 w-full py-4 bg-blue-600 hover:bg-blue-700 rounded text-sm font-bold text-gray-50 transition duration-200'
            >
              추천인 추가
            </button>
          </div>
        </form>
        <div className='text-red-500 row-auto text-sm'>
          <div>{errMsg}</div>
        </div>
        <div className='text-green-500 row-auto text-sm'>
          <div>{success}</div>
        </div>
      </div>
    </div>
  );
}

export default AddRecoPage;
