import { useState, useEffect } from 'react';
import apiClient from '../api/axios';


function UserList() {
  const [users, setUsers] = useState();
  const [errMsg, setErrMsg] = useState();


  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUsers = async () => {
      try {
        setErrMsg('처리중...');
        const response = await apiClient.get('/get/usersbymanager', {
          signal: controller.signal,
        });
        const users = response?.data;
        console.log(response?.data);
        isMounted && setErrMsg('');
        isMounted && setUsers(users);
      } catch (error) {
        console.log(error);
        if (!error?.response) {
          console.log('somthing wrong!');
        } else if (error.response?.status === 401) {
          console.log(error);
          setErrMsg('인증실패');
        } else {
          console.log(error);
          console.log('sonething not working');
        }
      }
    };
    getUsers();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <div className='text-center text-white flex justify-center flex-col'>
      <div className='self-center p-4 m-4 bg-slate-700 shadow rounded-lg text-stone-200'>
        <table>
          <thead>
            <tr>
              <th className='border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-normal'>
                #
              </th>
              <th className='border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-normal'>
                email
              </th>
              <th className='border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-normal'>
                name
              </th>
              <th className='border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-normal'>
                phone
              </th>
              <th className='border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-normal'>
                level
              </th>
              <th className='border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-normal'>
                expired
              </th>
            </tr>
          </thead>
          <tbody>
          {users?.map((user, i) => (
             

              <tr className='text-gray-300' key={i}>
              <td className='border-b-2 p-4 dark:border-dark-5'>{i}</td>
              <td className='border-b-2 p-4 dark:border-dark-5'>{user.email}</td>
              <td className='border-b-2 p-4 dark:border-dark-5'>{user.name}</td>
              <td className='border-b-2 p-4 dark:border-dark-5'>{user.phone}</td>
              <td className='border-b-2 p-4 dark:border-dark-5'>{user.productType}</td>
              <td className='border-b-2 p-4 dark:border-dark-5'>{user.finalDate}</td>
            </tr>
              
            ))}
            
          </tbody>
        </table>
      </div>
      <div>
        <h1>{errMsg}</h1>
      </div>
    </div>
  );
}

export default UserList;
