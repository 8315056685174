import { useRef, useState, useEffect } from 'react';
import apiClient from './api/axios';
import useAuth from './Hooks/useAuth';
import { useLocation, useNavigate } from 'react-router-dom';

function SignIn() {
  const { setAuth } = useAuth();

  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || '/';

  const emailRef = useRef();

  const [email, setEmail] = useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  const handlesumit = async (e) => {
    e.preventDefault();
    //api request

    try {
      setErrMsg('처리중.....');
      setPwd('');
      const response = await apiClient.post(
        'auth',
        JSON.stringify({ email, password: pwd }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const token = response?.data?.token;
      const role = response?.data?.role;
      localStorage.setItem('token', token);
      setAuth({ token, role, email });
      setErrMsg('');
      console.log(response.data);
      navigate(from, { replace: true });
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        console.log('somthing wrong!');
      } else if (error.response?.status === 401) {
        console.log(error);
        setErrMsg('인증실패.. 아이디 비번 확인');
      } else {
        console.log(error);
        console.log('sonething not working');
      }
    }
  };

  return (
    <section className='flex justify-center items-center h-screen bg-gray-800'>
      <div className='max-w-md w-full bg-slate-300 rounded p-6 space-y-4'>
        <div className='mb-4'>
          <p className=' text-sm text-gray-700 font-bold'>
            누리네트워크 관리자 시스템
          </p>
        </div>
        <form onSubmit={handlesumit} className='space-y-4'>
          <div>
            <input
              id='email'
              autoComplete='on'
              onChange={(e) => setEmail(e.target.value.toLowerCase())}
              required
              ref={emailRef}
              className='focus:border-blue-500 w-full p-4 text-sm bg-gray-50 focus:outline-none  border border-gray-200 rounded text-gray-600'
              type='text'
              placeholder='Email'
              value={email}
            />
          </div>
          <div>
            <input
              id='pwd'
              onChange={(e) => setPwd(e.target.value)}
              required
              className='focus:border-blue-500 w-full p-4 text-sm bg-gray-50 focus:outline-none border border-gray-200 rounded text-gray-600'
              type='text'
              placeholder='Password'
              value={pwd}
            />
          </div>
          <div>
            <button
              disabled={email && pwd ? false : true}
              className='disabled:bg-gray-400 w-full py-4 bg-blue-600 hover:bg-blue-700 rounded text-sm font-bold text-gray-50 transition duration-200'
            >
              로그인
            </button>
          </div>
        </form>
        <div className='text-red-500 row-auto text-sm'>
          <div>{errMsg}</div>
        </div>
        {/* <Link to='/register'>
          <p className=' text-right'>회원가입</p>
        </Link> */}
      </div>
    </section>
  );
}

export default SignIn;
